import axios from 'axios';
import { setUser } from '../Redux/Slices/userSlice';
// Base URLs and API keys (can be stored in environment variables for security)

const REACT_APP_API_BASE_URL = "https://dashboard.faangai.com/api"
const BASE_URL = REACT_APP_API_BASE_URL || '/api';

// Helper function to check if the token has expired
export const handleApiError = (error) => {
    if (error.response && error.response.data && error.response.data.msg === "Token has expired") {
        console.log("Hello user your token has expired");
        localStorage.setItem('logoutMessage', 'User is logged out'); // Add logout message
        localStorage.removeItem('userData');
    }
};

// Function to send email
export const sendEmail = async (emailType, recipientEmail, dynamicData) => {
    try {
        const response = await axios.post(`${BASE_URL}/send-email`, {
            to_email: recipientEmail,
            email_type: emailType,
            dynamic_template_data: dynamicData,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        console.log('Email sent successfully:', response.data);
    } catch (error) {
        console.error('Error sending email:', error.response ? error.response.data : error.message);
    }
};

// Function to authenticate and generate JWT
export const authenticateAndGenerateJWT = async (id, email) => {
    try {
        console.log("id", id)
        console.log("email", email)
        const response = await axios.post(
            `${BASE_URL}/login`,  // Use the base URL for the login endpoint
            {
                email: email,
                id: id
            }
        );

        console.log('JWT token received:', response.data.access_token);
        const accessToken = response?.data?.access_token;
        localStorage.setItem('accessToken', accessToken);
    } catch (error) {
        console.error(`Authentication failed for user: ${email}`);
        console.error(`Server response status: ${error.response.status}`);
        console.error(`Error details: ${error.response.data.error || error.response.data}`);
    }
};

// Function to store user data
export const storeUserData = async (userData) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/store-user-data`,
            userData,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        console.log('User data stored successfully:', response.data);
        if (response.data.message === "User registered successfully") {
            sendEmail('signup', userData?.email, { first_name: userData?.fullName });
        }
        const accessToken = response?.data?.access_token;
        localStorage.setItem('accessToken', accessToken);
        console.log('User data stored successfully:', accessToken);
        localStorage.setItem('userData', JSON.stringify(userData));
        const message = response.data.message;

        return {
            message: message,
            token: accessToken
        };
    } catch (error) {
        console.error('Error storing user data:', error.response?.data || error.message);
        handleApiError(error);
    }
};



export const createBuddy = async (email, userId, agentName, emoji, buddyType, templateID, templateName, selectedLanguage) => {
    try {
        // Make the POST request to the API
        const response = await axios.post(`${BASE_URL}/create-buddy`, {
            user_id: userId,
            buddy_name: agentName,
            user_email: email,
            emoji: emoji,
            buddy_type: buddyType,
            template_id: templateID,
            template_name: templateName,
            buddy_language: selectedLanguage,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Assuming you store the JWT in localStorage
                'Content-Type': 'application/json',
            },
        });

        // Handle the response
        console.log('Success:', response.data);
        return response.data; // You can return this for further use

    } catch (error) {
        // Handle errors
        console.error('Error creating buddy:', error.response ? error.response.data : error.message);
        throw error; // Rethrow or handle as needed
    }
};

export const getAllBuddies = async (userId, userEmail) => {
    try {
        console.log("getAllBuddies")
        const response = await axios.get(`${BASE_URL}/get-all-buddies`, {
            params: {
                user_id: userId,
                user_email: userEmail,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Assuming you store the JWT in localStorage
                'Content-Type': 'application/json',
            },
        });
        console.log("returning response", response.data)
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error.response && error.response.data
            ? error.response.data.error
            : "An error occurred";
    }
};



export const updateBuddyDetails = async (formData) => {
    try {
        const response = await axios.put(`${BASE_URL}/update-buddy-details`, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error.response && error.response.data
            ? error.response.data.error
            : "An error occurred";
    }
};



export const ProcessInterviewDetails = async (formData) => {
    try {
        const response = await axios.post(`${BASE_URL}/process-interview-details`, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'multipart/form-data', // Important for file uploads
            },
        });

        return response.data; // Return the response data to be handled by the caller
    } catch (error) {
        handleApiError(error);
        console.error("Error during the API call:", error);
        throw error; // Rethrow the error to be handled by the caller
    }
};


export const updateBuddyName = async (userId, agentId, userEmail, newAgentName) => {
    try {
        const response = await axios.put(
            `${BASE_URL}/update-buddy-name`,
            {
                user_id: userId,
                agent_id: agentId,
                user_email: userEmail,
                new_agent_name: newAgentName,
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }
        );

        if (response.status === 200) {
            console.log('Buddy name updated successfully:', response.data);
        } else {
            console.error('Error updating buddy name:', response.data);
        }
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
};


export const deleteBuddy = async (userId, agentId, userEmail) => {
    try {
        const response = await axios.delete(`${BASE_URL}/delete-buddy`, {
            params: {
                user_id: userId,
                agent_id: agentId,
                user_email: userEmail,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Assuming you store the JWT in localStorage
                'Content-Type': 'application/json',
            },
        });
        console.log('Buddy deleted:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error deleting buddy:', error.response?.data || error.message);
        throw error;
    }
};


export const updateBuddyIcon = async (userId, agentId, userEmail, newEmoji) => {
    try {
        const response = await axios.put(`${BASE_URL}/update-buddy-icon`, {
            user_id: userId,
            agent_id: agentId,
            user_email: userEmail,
            new_emoji: newEmoji,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
            },
        });
        console.log('Buddy icon updated:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating buddy icon:', error.response?.data || error.message);
        throw error;
    }
};

export const fetchReport = async (callId, userId) => {
    try {
        const response = await axios.post(`${BASE_URL}/get-generated-report`, {
            call_id: callId,
            user_id: userId,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data[0]; // Return the report data
    } catch (error) {
        handleApiError(error);
        // Handle errors (optional: you could throw this error for handling in the component)
        throw error.response?.data?.error || 'An error occurred while fetching the report.';
    }
};

// apiService.js
export const getAllAssessmentReports = async (userId) => {
    try {
        const response = await fetch(`${BASE_URL}/get-all-assessment-report`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify({
                user_id: userId,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch reports');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};


// Function to send PDF Blob to backend
export const sendPdfToBackend = async (blob, userId, requestId) => {
    try {
        const formData = new FormData();
        formData.append("file", blob, "assessment_report.pdf");
        formData.append("user_id", userId);
        formData.append("request_id", requestId);

        const response = await axios.post(`${BASE_URL}/upload-assessment-pdf`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        if (response.status === 200) {
            console.log("PDF successfully uploaded to Azure Blob Storage!");
        }
    } catch (error) {
        console.error("Error uploading PDF:", error);
    }
};



export const getUserById = async (userId, email) => {
    try {
        const response = await axios.get(`${BASE_URL}/get-user/${userId}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            params: {
                email: email,  // Query parameter (partition key)
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        if (error.response) {
            // Server-side error
            console.error('Error response:', error.response.data);
        } else {
            // Network or other errors
            console.error('Error message:', error.message);
        }
    }
};

export const updateUser = async (userId, email, updateData) => {
    try {
        const response = await axios.patch(`${BASE_URL}/update-user/${userId}`, updateData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            params: { email: email },
        });
        return response.data;
    } catch (error) {
        console.error("Error updating user data:", error);
        throw error;
    }
};


export const sendPaymentMethodToBackend = async (paymentMethodId, userId) => {
    try {
        const response = await fetch(`${BASE_URL}/add-card`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // JWT stored in localStorage

            },
            body: JSON.stringify({
                userId, // Send the userId along with the payment method ID
                paymentMethodId,
            }),
        });

        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.error || 'Failed to save payment method');
        }

        console.log('Payment method saved successfully:', result);
    } catch (err) {
        handleApiError(err);
        console.error('Error sending payment method to backend:', err.message);
    }
};

export const getBillingHistory = async (userId) => {
    try {
        const response = await axios.get(`${BASE_URL}/billing-history/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        console.error('Error fetching billing history:', error);
        throw error;
    }
};


export const deleteCard = async (userId, paymentMethodId) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/delete-card`,
            {
                userId: userId,
                paymentMethodId: paymentMethodId,
            },
            {
                headers: {
                    "Content-Type": "application/json", // Assuming JSON payload
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // JWT stored in localStorage
                },
            }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        console.error('Error deleting card:', error);
        throw error;
    }
};

export const getSavedCards = async (userId) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/get-saved-cards`,
            { userId: userId },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        console.error('Error fetching saved cards:', error);
        throw error;
    }
};

// Fetch client secret for Stripe setup
export const createSetupIntent = async (userId) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/create-setup-intent`,
            { userId },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        console.error('Error fetching client secret:', error);
        throw error;
    }
};

export const createPaymentIntent = async (amountInCents, userId, numberOfCoins) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/create-payment-intent`,
            {
                amount: amountInCents, // Amount in cents
                userId: userId,
                numberOfCoins: numberOfCoins,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }
        );
        return response.data; // Return the response data, including clientSecret
    } catch (error) {
        handleApiError(error);
        console.error('Error creating payment intent:', error);
        throw error; // Propagate the error to be handled by the calling function
    }
};

export const getCallHistory = async (user_email) => {
    try {
        const response = await axios.get(`${BASE_URL}/get-call-history`, {
            params: { user_email },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });
        return response.data; // Return the data if request is successful
    } catch (error) {
        handleApiError(error)
        console.error('Error fetching call history:', error);
        throw error; // Throw error to be handled in the component
    }
};

// Function to send feedback
export const sendFeedback = async (feedbackData) => {
    try {
        // Set headers, including authorization token
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        };

        // Make the POST request to send feedback
        const response = await axios.post(`${BASE_URL}/send-feedback`, feedbackData, config);

        // Return the response data
        return response.data;
    } catch (error) {
        handleApiError(error)
        console.error('Error sending feedback:', error.response ? error.response.data : error.message);
        // Handle errors (return them or throw them to be handled by the calling component)
        throw error.response ? error.response.data : error;
    }
};


export const getProfilePicture = async (userId) => {
    try {
        const response = await axios.get(`${BASE_URL}/get-profile-picture/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });
        return response.data; // or response if you need the full response
    } catch (error) {
        handleApiError(error)
        if (error.response && error.response.data.message === "No profile picture found") {
            // Return a default value or handle gracefully
            console.warn('No profile picture found for user:', userId);
            return null; // or any default value you prefer
        }
        console.error('Error fetching profile picture:', error);
        throw error; // Rethrow if it's an unexpected error
    }
};



export const uploadProfilePicture = async (userId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`${BASE_URL}/upload-profile-picture/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            }
        });
        return response.data; // Returns { url: 'profilePictureUrl' } on success
    } catch (error) {
        handleApiError(error)
        console.error("Error uploading profile picture:", error);
        throw error; // Throw the error to handle it in the calling component
    }
};

// Function to delete a user from Cosmos DB, Firebase, and related data
export const deleteUserAccount = async (userId, email) => {
    console.log("deleteUserAccount......")
    try {
        const response = await axios.delete(`${BASE_URL}/delete-user/${userId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Use access token for authentication
            },
            params: {
                email: email, // Partition key as query parameter
            },
        });

        return response.data; // Return the success message from the backend
    } catch (error) {
        handleApiError(error)
        console.error("Error deleting user:", error.response ? error.response.data : error.message);
        throw error; // Throw the error to handle it in the component
    }
};

export const checkToken = async (token) => {
    if (!token) {
        console.error("No access token found.");
        return false;
    }
    try {
        const response = await axios.get(`${BASE_URL}/check_token`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.status === 200;
    } catch (error) {
        console.error("Token is invalid or expired:", error.response ? error.response.data : error.message);
        return false;
    }
};



// Function to update buddy data
export const updateBuddyData = async (formData) => {
    try {
        const url = `${BASE_URL}/update-buddy-data`;
        // Send PUT request with authorization token
        const response = await axios.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error updating buddy data:', error);
        throw error;  // Optionally, rethrow to handle error in calling function
    }
};


// Redeem Promo Code
export const redeemPromoCode = async (promoCode, userEmail) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/promo-code/redeem`,
            {
                promoCode,
                userEmail: userEmail,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};


export const ValidatePromoCode = async (promoCode, userEmail) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/promo-code/validate`,
            {
                promoCode,
                userEmail
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};


export const redeemPromoCodeAPI = async (promoCode, userEmail, userId) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/refer-promo-code/redeem`,
            {
                promoCode,
                userEmail,
                user_id: userId,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            }
        );
        return response.data; // Returns the data object from the response
    } catch (error) {
        throw error.response?.data?.error || "Failed to redeem promo code.";
    }
};


export const callMockTemplatesAPI = async (templateInfo, userData) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/mock-templates-interview`, 
            { templateInfo, userData }, // Request body
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Retrieve JWT token
                },
            }
        );
        return response.data; // Return the API response
    } catch (error) {
        console.error("Error calling mock templates API:", error);
        throw error; // Throw the error to handle it in the calling function
    }
};
